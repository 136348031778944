<template>
  <div>
    <!-- .campanha-preview -->
    <MemberGetMember
      :landingPageOptions="landingPageOptions"
    />
    <Simulation :landingPageOptions="landingPageOptions"/>
    <Icon/>
    <Nav/>
    <div class="grid-box">
			<Breadcrumb :levels="breadcrumbLevels"/>
      <div class="main-block" :class="pageOptions.FooterClass">
        <router-view :page-options="pageOptions" :ladingPageOptions="landingPageOptions" @changeLevels="changeLevels"/>
      </div>
    </div>
  </div>
</template>

<script type="plain/text">
import Nav from "@/components/Nav.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import Icon from "@/components/Icon.vue";
import Simulation from "@/components/Simulation.vue";
import MemberGetMember from "@/components/MemberGetMember.vue";
import { Routes } from "@/scripts/models/enums/routes.enum.js";
import PageOptions from "@/scripts/models/pageOptions.model";
import CampaignLandingPageOptions from "@/scripts/models/campaignLandingPageOptions.model";
export default {
  nome: "campanha",
  components: {
    Nav,
    Icon,
    Simulation,
    Breadcrumb,
    MemberGetMember
  },
  data() {
    return {
      pageOptions: new PageOptions(),
      landingPageOptions: new CampaignLandingPageOptions(),
      breadcrumbLevels: [
				{ text: "Campanha" }
				// { pathName: Routes.app.Campaign, text: "Campanha" }
			]
    };
  },
  methods:{
    changeLevels(levels) {
      this.breadcrumbLevels = levels;
    }
  }
};
</script>
